import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Course Notes: `}<em parentName="h1">{`JavaScript Testing Practices and Principles`}</em></h1>
    <p>{`Here are the notes I took when working through the `}<em parentName="p">{`JavaScript Testing Practices and Principles`}</em>{` course by Kent C. Dodds.`}</p>
    <h2>{`Testing in general`}</h2>
    <p>{`These are some key testing takeaways I gained from this course.`}</p>
    <h3>{`Don't waste time`}</h3>
    <p>{`TDD usually isn’t great for UI development or development of anything that will change often.`}</p>
    <p>{`Kent spends most of his time on integration tests because e2e tests tend to be expensive and slow. However, tooling is getting better and failing e2e tests are usually the most costly to the user. Cypress is a promising tool for e2e testing. Cypress runs in context of the browser which makes a strong case against `}<a parentName="p" {...{
        "href": "https://martinfowler.com/bliki/PageObject.html"
      }}>{`the Page Object Model`}</a>{` which was nice for Selenium tests with no browser context.`}</p>
    <h3>{`Think of other developers`}</h3>
    <p>{`Your tests should work but also express intent. Future developers need to know what is important in your test implementation.`}</p>
    <p><strong parentName="p">{`Example #1:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const userToTest = myPotentiallyUnsafeUser;
// assert userToTest is safe to be serialized to JSON
`}</code></pre>
    <p>{`vs`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const safeUser = { id: 1, name: "Jane" };
const userToTest = {
  ...safeUser,
  myPotentiallyUnsafeData
};
// assert userToTest is safe to be serialized to JSON
`}</code></pre>
    <p><strong parentName="p">{`Example #2:`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const objectToTest = {
  id: 1,
  type: "mock",
  ...otherUnimportantProps, // take up space and increase noise, especially when repeated
  importantPropForTest: true
};
// assert something about objectToTest
`}</code></pre>
    <p>{`vs`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const objectToTest = baseObjectWithUnimportantProps;
objectToTest.importantPropForTest = true;
// assert something about objectToTest
`}</code></pre>
    <h3>{`Think of your users`}</h3>
    <p>{`You want to test from the user's perspective.`}</p>
    <ul>
      <li parentName="ul">{`Avoid testing implementation details.`}</li>
      <li parentName="ul">{`Test the way users will consume your app.`}</li>
      <li parentName="ul">{`When co-locating tests make sure not to ship your tests to your users.`}</li>
    </ul>
    <h2>{`Jest-specific Notes`}</h2>
    <p>{`This course was full of Jest goodies. Here are some that stuck out to me.`}</p>
    <h3>{`Mocking`}</h3>
    <p>{`I found it helps to think of `}<inlineCode parentName="p">{`jest.mock`}</inlineCode>{` as an entirely new module. It will swap out whatever import there is with what is returned. Under the hood, Jest takes control of the module system in Node forcing imports to go through the Jest registry first.`}</p>
    <p>{`When mocking a module, you can import the actual module if you need the real thing for whatever reason using the `}<inlineCode parentName="p">{`jest.requireActual`}</inlineCode>{` function. Here's an example from `}<a parentName="p" {...{
        "href": "https://jestjs.io/docs/en/jest-object#jestrequireactualmodulename"
      }}>{`the `}<inlineCode parentName="a">{`jest.requireActual`}</inlineCode>{` docs`}</a>{` of partially mocking a module.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`jest.mock("../myModule", () => {
  // Require the original module to not be mocked...
  const originalModule = jest.requireActual(moduleName);

  return {
    __esModule: true, // Use it when dealing with esModules
    ...originalModule,
    getRandom: jest.fn().mockReturnValue(10)
  };
});
const getRandom = require("../myModule").getRandom;

getRandom(); // Always returns 10
`}</code></pre>
    <p>{`Another neat thing Jest allows you to do is mock an entire module for all tests by creating a `}<inlineCode parentName="p">{`__mocks__`}</inlineCode>{` directory as a sibling to the module(s) to test and including the mock implementation in a new file inside the `}<inlineCode parentName="p">{`__mocks__`}</inlineCode>{` directory with the same name as the module you're mocking. Now Jest will import from that mock file rather than the real module throughout your tests where you use `}<inlineCode parentName="p">{`jest.mock(<pathToRealModule>)`}</inlineCode>{`. You can also mock `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` by creating a `}<inlineCode parentName="p">{`__mocks__`}</inlineCode>{` folder in the root directory of your workspace and following this pattern.`}</p>
    <p>{`Also, to facilitate tests running in total isolation from each other you can clear a mock in `}<inlineCode parentName="p">{`beforeEach`}</inlineCode>{` using `}<inlineCode parentName="p">{`myMockedFn.mockClear()`}</inlineCode>{`. For example, without this your tests would be failing if you're asserting `}<inlineCode parentName="p">{`myMockedFn`}</inlineCode>{` was called a certain number of times in multiple tests.`}</p>
    <h3>{`Assertion Functions`}</h3>
    <p><inlineCode parentName="p">{`toBe`}</inlineCode>{` compares objects by reference.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`expect({}).not.toBe({});
`}</code></pre>
    <p><inlineCode parentName="p">{`toEqual`}</inlineCode>{` compares objects by value.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`expect({}).toEqual({});
`}</code></pre>
    <p><inlineCode parentName="p">{`toMatchObject`}</inlineCode>{` is similar to `}<inlineCode parentName="p">{`toEqual`}</inlineCode>{`, but for partial equality.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      